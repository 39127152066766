<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1 mb-3">Why choose Ejaftech?</h2>
      <p class="desc my-3">
        We are devoted to performing the ERP implementation in the best possible
        way. One can guarantee by just working among us that we exhibit:
      </p>
      <ul class="desc my-3">
        <li>
          <p>
            Years of experience in providing effective ERP projects and our team
            is highly knowledgeable
          </p>
        </li>
        <li>
          <p>Solid information of the businesses.</p>
        </li>
        <li>
          <p>
            Helpful to our clients by improving their relative business
            procedures.
          </p>
        </li>
        <li>
          <p>
            Providing the finest service quality in the entire software
            marketplace is our main objective.
          </p>
        </li>
        <li>
          <p>The safety of client’s information is our top priority.</p>
        </li>
        <li>
          <p>
            We have the skill to provide our clients with diverse workflows and
            solutions.
          </p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/software-management/ejaf-erp/why-ejaf.png"
        alt="Why choose Ejaftech?"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "eightth-ejaf-erp-section ",
};
</script>
