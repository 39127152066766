<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1 mb-3">ERP Training</h2>
      <p class="desc my-3">
        The workforce dislike amendments. The staff is devoted to their work
        with tasks assigned daily which leads to obstruction of the ERP
        solution’s chances of adoption. An optimum way to deal with this
        resistance is that the organizations should train the staff with
        reference to the new ERP, thus allowing them to work freely in an
        innovative atmosphere with ERP training.
      </p>
      <p class="desc my-3">
        In order to make sure that everything works accurately for your
        business, the basic training is also provided to the staff by our expert
        training team which has a complete understanding of the user requirement
        as well as the basic demographic features upon which the training
        material is based.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/software-management/ejaf-erp/erp-training.png"
        alt="ERP Training"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "sixth-ejaf-erp-section ",
};
</script>
