<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1 mb-3">HD IP Cameras Cameras</h2>
      <p class="desc my-3">
        Durable High definition videos cameras are one of the well-founded
        IPCCTV cameras which are available indoor, outdoor, at any time, whether
        it’s day or night for capturing videos and images. Moreover, specialized
        video cameras are also there serving as a means of intelligent security
        solutions. They are specially designed for special PC vision; it can be
        used indoor-outdoor, at night, or day time and even in a tough
        environment, you can carry out your mission by using these specialized
        cameras.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/intelligent-security/ip-cctv/ip-cctvimg-2.png"
        alt="Explain Storage?"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "second-ip-cctv-section ",
};
</script>
