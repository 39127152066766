<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1 mb-3">
        Features For Video Surveillance Application
      </h2>
      <p class="desc my-3">
        Upgradable video imaging, high capacity storage, and computer vision
        programs have been featured specially for intelligence video
        Surveillance (IVS) applications, thereby providing intelligent security
        solutions.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/intelligent-security/ip-cctv/ip-cctvimg-3.png"
        alt="Explain Storage?"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "third-ip-cctv-section ",
};
</script>
