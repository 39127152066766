<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1 mb-3">Encoder & Decoder</h2>
      <p class="desc my-3">
        Audio and video decoders are available, which are a means of intelligent
        security solutions and are used for tight security surveillance. They
        also ensure the live monitoring of applications.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/intelligent-security/ip-cctv/ip-cctvimg-4.png"
        alt="Encoder & Decoder"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "fourth-ip-cctv-section ",
};
</script>
