<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1 mb-3">ERP Customization</h2>
      <p class="desc my-3">
        Those businesses that want to customize their ERP according to their
        requirements are mainly looking out for customization services. Through
        customization, numerous companies have brought their work procedure to
        life.
      </p>
      <p class="desc my-3">
        The system is customized according to the requirements of staff and is
        much more efficient and reliable.
      </p>
      <p class="desc my-3">
        Since we have a full understanding of ERP here at Ejaftech, we can
        customize the system according to requirements of the enterprise without
        any difficulty.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/software-management/ejaf-erp/customization.png"
        alt="ERP Customization"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "fiveth-ejaf-erp-section",
};
</script>
