<template>
  <ejaf-erp />
</template>

<script>
import EjafErp from "../../components/solutions/software-management/EjafErp.vue";
export default {
  name: "ejaf-erp-page",
  components: { EjafErp },
};
</script>
