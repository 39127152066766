<template>
  <ip-cctv />
</template>

<script>
import IpCctv from "../../components/solutions/intelligent-security/IpCctv.vue";
export default {
  name: "ip-cctv-page",
  components: { IpCctv },
};
</script>
