<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1 mb-3">ERP Implementation</h2>
      <p class="desc my-3">
        EJAF ERP offers a globally spread business software such as Purchasing,
        Economic, Inventory, Sales, Manufacturing/Trade, Project Management,
        etc. All of which are incorporated entirely in a single front-end and
        back-end office system over all the locations, which involves business
        statistics/analytics as well as other cellphone applications.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/software-management/ejaf-erp/ejaf-erp2.png"
        alt="Explain Storage?"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "first-ejaf-erp-section",
};
</script>
