<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1 mb-3">
        Power Supply Systems for Video Surveillance
      </h2>
      <p class="desc my-3">
        There are fresh, quiet, and authentic power plants that keep video
        surveillance going on. Also, there are specialized power supplies that
        are specifically designed for running of intelligent video
        surveillance(IVS).
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/intelligent-security/ip-cctv/ip-cctvimg-5.png"
        alt="Explain Storage?"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "fiveth-ip-cctv-section ",
};
</script>
