<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1 mb-3">ERP Support</h2>
      <p class="desc my-3">
        It is quite challenging to find a decent ERP support, but consider your
        search to be over if you are on this page. The main objective of Ejaf
        tech is to grant free access to its butter and bread, hence it is the
        top leading ERP service provider corporation.
      </p>
      <p class="desc my-3">
        Ejaf tech is best known for providing ERP support. The trip begins with
        a full-scope implementation of ERP. We are considerate regarding our
        implementations and propose to our customers the most inexpensive,
        first-class and cheap ERP support.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/software-management/ejaf-erp/erp-support.png"
        alt="ERP Support"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "seventh-ejaf-erp-section",
};
</script>
