<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1 mb-3">ERP Consultant</h2>
      <p class="desc my-3">
        In order to look out for all the business procedures and to automate
        these procedures with utmost proficiency, the central approach used is
        ERP (Enterprise Resource Planning). <br />
        For any type of enterprise, the most challenging job is that of
        selecting an ERP system. Selecting ERP means retrieving the market
        information, searching demos, and then employing a devoted team in order
        to perform the ERP implementation.
      </p>
      <p class="desc my-3">
        To facilitate this procedure, ERP consulting is provided by Ejaf tech
        for those enterprises that wish to select the ERP system for their
        business.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/software-management/ejaf-erp/ejaf-erpimg-8.png"
        alt="ERP Consultant"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "fourth-ejaf-erp-section ",
};
</script>
