<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1 mb-3">Video Surveillance</h2>
      <ul class="desc my-3">
        <li>
          <p>
            Many different products are being offered by Huawei for constructing
            and control efficient video monitoring systems, including authentic,
            top quality video display, efficiently working networks, and storage
            products.
          </p>
        </li>
        <li>
          <p>
            Cameras that have high image quality and HD video quality. Besides,
            it has a built-in intelligence engineered that manages the design,
            looks of the system, and enhances the rate of its development.
          </p>
        </li>
        <li>
          <p>
            Upgradable image processor, Codec, and high capacity storage system.
          </p>
        </li>
        <li>
          <p>
            The modular solar system is also present to provide long-lasting and
            cost-cutting facilities.
          </p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/intelligent-security/ip-cctv/ip-cctvimg-1.png"
        alt="Explain Storage?"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "first-ip-cctv-section ",
};
</script>
