<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1 mb-3">ERP Implementation</h2>
      <p class="desc my-3">
        The most important component of any sort of business transformation
        procedure is ERP Implementation. Choosing the right implementation can
        not only save money and time but can also provide a massive boost to the
        overall progress of an entire organization.
      </p>
      <p class="desc my-3">
        An end-to-end ERP implementation is offered at Ejaftech and one can
        obtain optimum results through the ERP implementation by working
        deliberately.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/software-management/ejaf-erp/ejaf-erp3.png"
        alt="ERP Implementation"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "second-ejaf-erp-section ",
};
</script>
