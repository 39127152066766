<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1 mb-3">ERP Development</h2>
      <p class="desc my-3">
        These days, there is no business that stays stationary and through the
        necessary modifications, businesses have to integrate all modules that
        are essential in ERP. This is where Ejaf tech steps up its game by
        offering some of the best development services for any company which
        makes use of ERP.
      </p>
      <p class="desc my-3">
        Thus, for ERP development, it is a must to have knowledge in various
        fields and technologies used specifically in ERP.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/software-management/ejaf-erp/ejaf-erp4.png"
        alt="Explain Storage?"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "third-ejaf-erp-section",
};
</script>
